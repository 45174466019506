.BlocckAutosHome {
    width: 1100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 10px auto;
    flex-wrap: wrap;
}
.BlocckAutosHomeLoader {
    width: 1100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 10px auto;
    flex-wrap: wrap;
}

@media (max-width: 1105px) {
    .BlocckAutosHome {
        width: 100%;
    }
    .BlocckAutosHomeLoader {
        width: 100%;
    }
    
}