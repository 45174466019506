
.SearchPropertyHomeCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.SearchPropertyHomeCenter .viewFilter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.SearchPropertyHomeCenter .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    border-radius: 30px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    margin: 5px;
    padding: 7px;
}
.SearchPropertyHomeCenter .search h3 {
    display: none;
}
.SearchPropertyHomeCenter .search .primary {
    background-color: var(--Black);
    border-radius: 25px 0 0 25px;
}
.SearchPropertyHomeCenter .search .address {
    width:100%;
}
.SearchPropertyHomeCenter .search input {
    width: 100%;
    background-color: var(--White);
    color: var(--Paragraph);
    padding: 10px;
    font-weight: 700;
    border:none;
    border-radius: 30px;
    height: 40px;
}
.SearchPropertyHomeCenter .search select {
    width:50%;
    background-color: var(--White);
    color: var(--Paragraph);
    padding: 10px;
    font-weight: 700;
    border:none;

    height: 40px;
}

.SearchPropertyHomeCenter .search input::placeholder {
    color: var(--Gray2);
    font-weight: 700;
}
.SearchPropertyHomeCenter .search datalist {
    position: absolute;
    background-color: white;
    border: 1px solid blue;
    border-radius: 0 0 5px 5px;
    border-top: none;
    font-family: sans-serif;
    padding: 5px;
    max-height: 10rem;
    
  }
.SearchPropertyHomeCenter .search .filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 2px 0px 2px;
    border-radius: 6px;
    background-color: var(--White);
    color: var(--Gray);
    font-weight: 500;
    border: none;
    height: 30px;
    width: 120px;
    margin: 0px 5px;
}
.SearchPropertyHomeCenter .search .filter svg {
    margin-right: 2px;
    font-size: 12px;
}
.SearchPropertyHomeCenter .viewFilter .search .btnSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 50px;
    background-color: var(--Primary);
    color: var(--White);
    font-weight: 500;
    border: none;
    height: 40px;
}
.SearchPropertyHomeCenter .search .btnSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: var(--Primary);
    color: var(--White);
    font-weight: 500;
    border: none;
    height: 40px;
}
.SearchPropertyHomeCenter .search .mobile {
    display: none;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: var(--Primary);
    color: var(--White);
    font-weight: 500;
    border: none;
    height: 40px;
}

.SearchPropertyHomeCenter .search .btnClear {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: none;
    background-color: var(--Border);
    padding: 3px;
    color: var(--Gray2);
    margin: 0 10px;
}


.SearchPropertyHomeCenter .search .search3 {
    display: none;
}
.SearchPropertyHomeCenter .search2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 90%;
    margin-bottom: 5px;
}

.SearchPropertyHomeCenter .search2 .listAdress {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width:100%;
    border-radius: 10px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    padding:5px;
    overflow: auto;
    max-height: 150px;
}

.SearchPropertyHomeCenter .search2 .listAdress h6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 98%;
    margin: 3px;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 20px;
    color: var(--Paragraph);
    font-weight: 700;
    cursor: pointer;
}


.SearchPropertyHomeCenter .search2 .listAdress .itemListAdress {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 98%;
    margin: 1px 3px;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 20px;
    color: var(--Gray2);
    font-weight: 500;
    cursor: pointer;
}

.SearchPropertyHomeCenter .search2 .listAdress::-webkit-scrollbar {
    width: 12px;  
    border-radius: 50px;              /* width of the entire scrollbar */
  }
  
  .SearchPropertyHomeCenter .search2 .listAdress::-webkit-scrollbar-track {
    background: var(--BorderHover);
    border-radius: 50px;         /* color of the tracking area */
  }
  
  .SearchPropertyHomeCenter .search2 .listAdress::-webkit-scrollbar-thumb {
    background-color: var(--Border);    /* color of the scroll thumb */
    border-radius: 50px;       /* roundness of the scroll thumb */
    border: 3px solid var(--BorderHover);  /* creates padding around scroll thumb */
  }




.SearchPropertyHomeCenter .selectButtonsHomeTop {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.SearchPropertyHomeCenter .selectButtonsHomeTop button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
    padding: 5px;
    background-color: rgb(0,0,0,0.3);
    border: none;
    color: var(--White);
    font-weight: 700;
    border-radius: 30px;
    margin: 3px;
}
.SearchPropertyHomeCenter .selectButtonsHomeTop button:hover {
    background-color: var(--Primary);
    border: 2px solid var(--Primary);
    color: var(--White);
}
.SearchPropertyHomeCenter .selectButtonsHomeTop button svg {
   margin-bottom: 1px;
   font-size: 16px;
}
.SearchPropertyHomeCenter .selectButtonsHomeTop .btn1 {
    padding: 5px;
    background-color: rgb(0,0,0,0.3);
    border: none;
    color: var(--White);
    font-weight: 700;
    margin: 3px;
}
.SearchPropertyHomeCenter .selectButtonsHomeTop .btn {
    padding: 5px;
    background-color: var(--Primary);
    border: 2px solid var(--Primary);
    color: var(--White);
    font-weight: 700;
    margin: 3px;
}
.SearchPropertyHomeCenter .selectButtonsHomeTop .btn2 {
    padding: 5px;
    background-color: var(--Primary);
    border: 2px solid var(--Primary);
    color: var(--White);
    font-weight: 700;
    margin: 3px;
}
.SearchPropertyHomeCenter .selectButtonsHomeTop .btn3 {
    padding: 5px;
    background-color: var(--Primary);
    border: 2px solid var(--Primary);
    color: var(--White);
    font-weight: 700;
    margin: 3px;
}
.SearchPropertyHomeCenter .selectButtonsHomeTop .btn4 {
    padding: 5px;
    background-color: rgb(0,0,0,0.3);
    border: none;
    color: var(--White);
    font-weight: 700;
    margin: 3px;
}

.SearchPropertyHomeCenter .textLocation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 10px 0;
    flex-wrap: wrap;
}
.SearchPropertyHomeCenter .textLocation .blockInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    flex-wrap: wrap;
}
.SearchPropertyHomeCenter .textLocation .blockInfo2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 5px;
    flex-wrap: wrap;
}

.SearchPropertyHomeCenter .textLocation .blockInfo h4 {
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--Description);
}

.SearchPropertyHomeCenter .textLocation .blockInfo select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    Background-color:var(--BorderHover);
    color: var(--Description);
    font-weight: 500;
    border: none;
    margin: 5px;
    border-radius: 6px;
}

.SearchPropertyHomeCenter .textLocation .blockInfo button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    Background-color:var(--Border);
    color: var(--Description);
    font-weight: 500;
    border: none;
    margin: 5px 10px;
    border-radius: 6px;
}


.SearchPropertyHomeCenter .textLocation .blockInfo .checkDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0px 5px;
}
.SearchPropertyHomeCenter .textLocation .blockInfo .checkDiv .ButtonState {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 5px;
    height: 18px;
    margin: 0px;
    background-color: var(--White);
    border: 2px solid var(--Gray);
}
.SearchPropertyHomeCenter .textLocation .blockInfo .checkDiv .ButtonSelect {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 5px;
    height: 18px;
    margin: 0px;
    background-color: var(--Description);
    border: 2px solid var(--White);
}

.SearchPropertyHomeCenter .textLocation .blockInfo .checkDiv h5 {
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--Description);
    font-weight: 700;
}

.SearchPropertyHomeCenter .textLocation .blockInfo .checkDiv h5 svg {
    margin-right: 3px;
}




.SearchPropertyHomeCenter .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0;
    flex-wrap: wrap;
}
.SearchPropertyHomeCenter .icons .unic {
    display: flex;
    flex-direction: row;
    align-items:  flex-start;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 20px;
    margin: 5px;
    background-color: rgba(99,99,99, 0.5);
    color: var(--White);
    cursor: pointer;
}

.SearchPropertyHomeCenter .icons .unic svg {
    margin-right: 5px;
}
.SearchPropertyHomeCenter .icons .unic:hover {
    background-color: rgba(237,50,55, 0.9);
}


.content-modal-City {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;

}

.content-modal-City .itensModal-City {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow:auto; 
    max-height: 500px;
    padding: 20px;
}
.content-modal-City .itensModal-City h3 {
    font-weight: 700;
    color: var(--Primary);
    margin: 20px;
}

.content-modal-City .itensModal-City .cep {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5px;
}

.content-modal-City .itensModal-City .cep input {
    width: 200px;
    padding: 5px 10px;
    border-radius: 6px;
    border: 2px solid var(--Border);
    margin: 3px;
}
.content-modal-City .itensModal-City .cep button {
    width: 80px;
    padding: 5px 10px;
    border-radius: 6px;
    border: none;
    background-color: var(--Primary);
    color: var(--White);
    margin: 3px;
}

.content-modal-City .itensModal-City .address {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5px;
}

.content-modal-City .itensModal-City .address input {
    width: 200px;
    padding: 5px 10px;
    border-radius: 6px;
    border: 2px solid var(--Border);
    margin: 3px;
}

.content-modal-City .itensModal-City .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5px;
}

.content-modal-City .itensModal-City .buttons button {
    width: 80px;
    padding: 5px 10px;
    border-radius: 6px;
    border: none;
    background-color: var(--Primary);
    color: var(--White);
    margin: 3px;
}

@media (max-width: 1200px) {


    @media (max-width: 1020px) {

        .SearchPropertyHomeCenter {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
        
        }

    @media (max-width: 900px) {
        .SearchPropertyHomeCenter .search {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 90%;

        }

        .SearchPropertyHomeCenter .selectButtonsHomeTop {
            width: 90%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

       
        
    
        @media (max-width: 700px) {   

            .SearchPropertyHomeCenter .search .filter {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                padding: 0px 2px 0px 2px;
                border-radius: 6px;
                background-color: rgb(255,255,255, 0.0);
                color: var(--BorderInput);
                font-weight: 500;
                border: none;
                height: 30px;
                width: 100%;
                margin: 5px 5px;
                font-size: 14px;
            }
            .SearchPropertyHomeCenter .search .filter svg {
                margin-right: 2px;
                font-size: 12px;
            }

            .SearchPropertyHomeCenter .search .mobile {
                display: none;
                border-radius: 50px;
            }



            .SearchPropertyHomeCenter {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin: 20px 0;
                z-index: 1;
                position: relative;
            }


          
            .SearchPropertyHomeCenter .search {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 95%;
                height: 100%;
                border: none;
                background: none;
                box-shadow: none;
                margin-top: -7px;

            }

            .SearchPropertyHomeCenter .search h3 {
                display:block;
                font-weight: bold;
                color: var(--White);
                margin: 10px;
            }
            .SearchPropertyHomeCenter .search .primary {
                width: 100%;
                height: 40px;
                border: 1px solid var(--White);
                border: 1px solid var(--Border);
                width: 100%;
                border-radius: 50px;
                margin-bottom: 5px;
            }

            .SearchPropertyHomeCenter .search input {
                width: 100%;
                border: 1px solid var(--White);
                border: 1px solid var(--Border);
                border-radius: 50px;
                margin-bottom: 5px;
            }
            
            .SearchPropertyHomeCenter .search select {
                width: 100%;
                border: 1px solid var(--White);
                border: 1px solid var(--Border);
                border-radius: 50px;
                margin-bottom: 5px;
            }
            
            .SearchPropertyHomeCenter .search input::placeholder {
                font-weight: 700;
            }
            .SearchPropertyHomeCenter .search button {
                width: 100%;
                padding: 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
            }

            .SearchPropertyHomeCenter .search2 {
                display: none;
            }
    
            .SearchPropertyHomeCenter .search  .search3 {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                width: 100%;
                margin-bottom: 5px;
            }
    
            
    
            .SearchPropertyHomeCenter .search  .search3 .listAdress {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                border-radius: 10px;
                border: 1px solid var(--Border);
                background-color: var(--White);
                padding:7px;
                margin: 5px 0px;
                overflow: auto;
                max-height: 150px;
            }
            
            .SearchPropertyHomeCenter .search  .search3 .listAdress h6 {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                width: 95%;
                margin: 3px;
                padding: 5px 10px;
                font-size: 14px;
                border-radius: 20px;
                color: var(--Paragraph);
                font-weight: 700;
                cursor: pointer;
            }

            .SearchPropertyHomeCenter .search  .search3 .listAdress .itemListAdress {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                width: 98%;
                margin: 1px 3px;
                padding: 5px 10px;
                font-size: 14px;
                border-radius: 20px;
                color: var(--Gray2);
                font-weight: 500;
                cursor: pointer;
            }
            
            .SearchPropertyHomeCenter .search  .search3 .listAdress::-webkit-scrollbar {
                width: 12px;  
                border-radius: 50px;              /* width of the entire scrollbar */
              }
              
              .SearchPropertyHomeCenter .search  .search3 .listAdress::-webkit-scrollbar-track {
                background: var(--BorderHover);
                border-radius: 50px;         /* color of the tracking area */
              }
              
              .SearchPropertyHomeCenter .search  .search3 .listAdress::-webkit-scrollbar-thumb {
                background-color: var(--Border);    /* color of the scroll thumb */
                border-radius: 50px;       /* roundness of the scroll thumb */
                border: 3px solid var(--BorderHover);  /* creates padding around scroll thumb */
              }

            .SearchPropertyHomeCenter .selectButtonsHomeTop .btn {
                width: 100%;
            }
            .SearchPropertyHomeCenter .selectButtonsHomeTop .btn1 {
                width: 100%;
            }
            .SearchPropertyHomeCenter .selectButtonsHomeTop .btn2 {
                width: 100%;
            }
            .SearchPropertyHomeCenter .selectButtonsHomeTop .btn3 {
                width: 100%;
            }
            .SearchPropertyHomeCenter .selectButtonsHomeTop .btn4 {
                width: 100%;
            }

            .SearchPropertyHomeCenter .selectButtonsHomeTop {
                width: 95%;
            }
            .SearchPropertyHomeCenter .selectButtonsHomeTop button {
                width: 100%;
            }


            .SearchPropertyHomeCenter .icons .unic {
                font-size: 14px;
            }

            @media (max-width: 480px) {
                .SearchPropertyHomeCenter .search {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 0px;
                }
                .SearchPropertyHomeCenter .selectButtonsHomeTop {
                    width: 100%;
                    justify-content: space-between;
                }

                .SearchPropertyHomeCenter .selectButtonsHomeTop button {
                    padding: 15px 10px;
                }

                
            }
        }
    }
    }
}