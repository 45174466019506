.Notifications {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0px 0px 0px;
}

.Notifications h2 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
}
.Notifications .textMessage {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Notifications .space {
    width: 100%;
    height: 60px;
}

@media (max-width: 768px) {
    .Notifications {
        width: 100%;
        height: 100%;
    }
    
}