.AutosModel {
    width: 1100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px;
    margin: 20px auto 50px auto;
    flex-wrap: wrap;
}
.AutosModel .UnicModel {
    width:200px;
    height: 200px;
    background-color: #000;
    border-radius: 22px;
    object-fit: cover;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
    margin: 5px;
}

.AutosModel .UnicModel img {
    width:100%;
    height: 100%;
    object-fit: cover;
    border-radius: 18px;
    opacity: 0.9;
}

.AutosModel .UnicModel h3 {
    position: absolute;
    z-index: 5;
    margin: -30px 10px 10px 10px;
    font-weight: 900;
    color: var(--BorderInput);
    text-shadow: 0.1em 0.1em 0.2em black
}

@media (max-width: 1150px) {
    .AutosModel {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 5px;
        margin: 20px auto 50px auto;
        flex-wrap: wrap;
    }

    @media (max-width: 750px) {
        .AutosModel {
            width: 95%;
        }


        @media (max-width: 684px) {
            .AutosModel {
                width: 95%;
                justify-content: center;
            }

            .AutosModel .UnicModel {
                width:45%;
                height: 150px;
                background-color: #000;
                border-radius: 22px;
                object-fit: cover;
                box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
                margin: 5px;
            }


            @media (max-width: 470px) {
                .AutosModel {
                    width: 95%;
                    justify-content: center;
                }
    
                .AutosModel .UnicModel {
                    width:95%;
                    height: 150px;
                    background-color: #000;
                    border-radius: 22px;
                    object-fit: cover;
                    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
                    margin: 5px;
                }
            }
        }
    }
}