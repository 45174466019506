.FipeCarData {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: var(--White);
    padding-top: 80px;
}
.FipeCarData .loaderImage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    background-color: var(--White);
}

.FipeCarData .loaderImage img {
    width: 50px;
}

.FipeCarData .car {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 90%;
    margin: 10px;
    padding: 10px;
}


.FipeCarData .car h2 {
    font-weight: 800;
    margin: 1px 0;
    color: var(--Primary);
}

.FipeCarData  h3 {
    font-weight: 700;
    margin: 10px 0;
    color: var(--Primary);
}


.FipeCarData .car h4 {
    font-weight: 700;
    margin: 1px 0;
    color: var(--Paragraph);
}
.FipeCarData .car h4 span {
    font-weight: 400;
    color: var(--Gray2);
}


.FipeCarData .versions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 90%;
    margin: 10px;
    padding: 0px;
    flex-wrap: wrap;
}
.FipeCarData .versions .versionUnic {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 32%;
    background-color: var(--BorderInput2);
    margin: 5px;
    padding: 10px;
    border-radius: 16px;
    color: var(--Description);
}

.FipeCarData .versions .versionUnic .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid var(--Border);
    margin: 3px 0;
}

.FipeCarData .versions .versionUnic h4 {
    font-weight: 700;
    margin: 2px 0;
    color: var(--Primary);
}
.FipeCarData .versions .versionUnic .text h5 {
    font-weight: 700;
    margin: 2px 0;
}
.FipeCarData .versions .versionUnic .text h5 span {
    font-weight: 400
}


.FipeCarData .versions .versionUnic .value {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width:100%;
    color: var(--White);
    margin-top: 5px;
    padding-top: 5px;
}
.FipeCarData .versions .versionUnic .value h4 {
    font-weight: 700;
}

.FipeCarData .new {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    padding: 40px 10px;
    background-color: var(--Primary);
}



.FipeCarData .new  h3 {
    font-weight: 700;
    margin: 10px 0;
    color: var(--White);
}


.FipeCarData .new .searchPlaca {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.FipeCarData .new .searchPlaca input {
    width: 300px;
    padding: 10px 20px;
    background: none;
    border: none;
    border-bottom: 1px solid var(--White);
    color: var(--White);
    margin: 5px;
    font-size: 36px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    text-align: center;
}

.FipeCarData .new .searchPlaca input::placeholder {
    color: var(--White);
  }

.FipeCarData .new .searchPlaca button {
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    background-color: var(--White);
    color: var(--Primary);
    margin: 5px;
}

.FipeCarData .msgError {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    margin: 40px 0;
    background-color: var(--BorderHover);
    padding: 20px;
    border-radius: 20px;
}

@media (max-width: 850px) {
    .FipeCarData .versions .versionUnic {
        width: 300px;
    }
    
@media (max-width: 500px) {
    .FipeCarData .new .searchPlaca {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }

    
    .FipeCarData .searchPlaca button {
        width: 300px;
    }

    .FipeCarData .msgError {
        width: 90%;
    }
}
}
