.SignUpProfessional {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.SignUpProfessional .login {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--White);
}

.SignUpProfessional .login form {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SignUpProfessional .login form img {
    height: 50px;
}

.SignUpProfessional .login form .data {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    padding: 10px;
}

.SignUpProfessional .login form .data input {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-bottom: 2px solid var(--Border);
    padding: 5px;
    margin: 5px 0;
    font-weight: bold;
}
.SignUpProfessional .login form .data select {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-bottom: 2px solid var(--Border);
    padding: 5px;
    margin: 5px 0;
    font-weight: bold;
    background-color: var(--White);
}
.SignUpProfessional .login form .data textarea {
    width: 80%;
    border: none;
    border-bottom: 2px solid var(--Border);
    padding: 5px;
    margin: 5px 0;
    font-weight: bold;
    resize: none;
}

.SignUpProfessional .login form .data input::placeholder {
    color: var(--Gray);
}
.SignUpProfessional .login form .data .links {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    color: var(--Gray);
    font-weight: bold;
    margin: 10px 0;
}

.SignUpProfessional .login form .data button {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Button);
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    color: var(--White);
    font-weight: 700;
    margin: 10px 0;
}
.SignUpProfessional .login form .data .btn {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Secondary);
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    color: var(--White);
    font-weight: 700;
    margin: 10px 0;
}


.SignUpProfessional .login form .data .label-avatar{
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    margin: 20px 0;
}

.SignUpProfessional .login form .data .label-avatar input {
    display: none;
}

.SignUpProfessional .login form .data .label-avatar span {
    z-index: 99;
    position: absolute;
    opacity: 0.7;
    transition: all .5s;
}

.SignUpProfessional .login form .data .label-avatar span:hover{
    opacity: 1;
    transform: scale(1.4);
}

.SignUpProfessional .login form .data .label-avatar img{
    margin-bottom: 1em;
    border-radius: 10px;
    object-fit: cover;
    width: 100px;
    height: 100px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}

.SignUpProfessional .professional {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/background5.jpg"); /* The image used */
    background-color: var(--Primary); /* Used if the image is unavailable */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.SignUpProfessional .professional .block {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(237, 50, 55, 0.3);
}

.SignUpProfessional .professional .block button {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Button);
    color: var(--White);
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    font-weight: 700;
    margin: 10px 0;
}




@media (max-width: 700px) {

    .SignUpProfessional .login form {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .SignUpProfessional .login {
        width: 100%;
        height: 100vh;
    }
    
    .SignUpProfessional .professional {
        display: none;
    }

    .SignUpProfessional .login form .data {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
        padding: 10px;
    }
    
    .SignUpProfessional .login form .data input {
        width: 90%;
    }
    .SignUpProfessional .login form .data select {
        width: 90%;
    }
    .SignUpProfessional .login form .data textarea {
        width: 90%;
    }
    
    .SignUpProfessional .login form .data input::placeholder {
        color: var(--Gray);
    }
    .SignUpProfessional .login form .data .links {
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        color: var(--Gray);
        font-weight: bold;
        margin: 10px 0;
    }
    
    .SignUpProfessional .login form .data button {
        width: 90%;
    }
    
    @media (max-width: 500px) {
        .SignUpProfessional .login form {
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .SignUpProfessional .login form .data {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 20px 0;
            padding: 10px;
        }
        
    }
}