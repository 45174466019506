.MessagesAutos {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}


.MessagesAutos .main {
    width: 100%;
    margin-top: 80px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.MessagesAutos .main .text {
    width: 80%;
    padding: 10px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin: 10px;
}
.MessagesAutos .main .text .textTop {
    width: 550px;
    padding: 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    fle-wrap: wrap;
}

.MessagesAutos .main .text .textTop h3 {
    font-weight: bold;
    text-align: center;
    margin: 10px;
}

.MessagesAutos .main .text .textTop button {
    border: none;
    color: var(--ButtonDisabled);
    background-color: var(--White);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin: 10px;
}

.MessagesAutos .main .text .textTop button svg {
    margin-right: 3px;
    font-weight: bold;
}

.MessagesAutos .main .text h2 {
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
}

.MessagesAutos .main .text h3 {
    font-weight: 700;
    color: var(--Paragraph);
    text-align: center;
}
.MessagesAutos .main .text h3 svg{
    font-size: 45px;
    font-weight: 900;
    color: var(--Primary)
}
.MessagesAutos .main .text a{
    text-decoration: none;
}
.MessagesAutos .main .text .chat{
    width: 550px;
    border-radius: 20px;
    background-color: var(--BorderInput);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 5px 10px;
}

.MessagesAutos .main .text .chat .image{
    border-radius: 20px 0 0 20px;
    width: 80px;
    height: 80px;
    object-fit: cover;
}
.MessagesAutos .main .text .chat .image img{
    border-radius: 20px 0 0 20px;
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.MessagesAutos .main .text .chat .textChat{
    border-radius: 10px;
    background-color: var(--BorderInput);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
    text-align:left;
}
.MessagesAutos .main .text .chat .textChat h3 {
    color: var(--Primary);
    font-weight: 700;
    margin: 0 10px;
    text-align:left;
  }

  .MessagesAutos .main .text .chat .textChat h5 {
    color: var(--Paragraph);
    margin: 0 10px;
    text-align:left;
  }
  .MessagesAutos .main .text .chat .textChat h6 {
    color: var(--Gray);
    margin: 5px 10px 0 10px;
    text-align:left;
  }

@media (max-width: 800px) {
    .MessagesAutos .main .text .chat{
        width: 450px;
        margin: 5px 0px;
    }

    .MessagesAutos .main .text .textTop {
        width: 450px;
    }
    @media (max-width: 735px) {
        .MessagesAutos .main .text .chat{
            width: 400px;
            margin: 5px 0px;
        }
    
        .MessagesAutos .main .text .textTop {
            width: 100%;
            jjustify-content: center;
        }
    @media (max-width: 700px) {
        .MessagesAutos .main {
            margin-top: 50px;
            margin-bottom: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }

        .MessagesAutos .main .text {
            width: 100%;
            padding: 0px;
        }
        
        @media (max-width: 530px) {

            .MessagesAutos .main .text .chat{
                width:400px;
            }

            .MessagesAutos .main .text .textTop {
                width: 100%;
            }
    
            .MessagesAutos .main .text .chat .textChat h3 {
                font-size: 16px;
              }
            
              .MessagesAutos .main .text .chat .textChat h5 {
                font-size: 12px;
              }
              .MessagesAutos .main .text .chat .textChat h6 {
                font-size: 10px;
              }
              @media (max-width: 430px) {

                .MessagesAutos .main .text .chat{
                    width:350px;
                }

              @media (max-width: 380px) {

                .MessagesAutos .main .text .chat{
                    width:300px;
                }
        }
        }
        }


    }
    }
}

