.DownloadApp2 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    width: 1100px;
    border-radius: 20px;
    height: 100%;
    /* background-image: url("../../assets/images/background2.jpg");
    background-color: #cccccc; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; */
    margin: 40px auto 40px auto;
}

.DownloadApp2 .imageAuto1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 90%;
}
.DownloadApp2 .imageAuto1 img {
    width: 100%;
}

.DownloadApp2 .imageAuto2 {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 90%;
}

.DownloadApp2 .back {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: rgba(237,50, 55, 0.7);
}

.DownloadApp2 .textSession {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 100px;
}

.DownloadApp2 .textSession h1 {
    color: var(--White);
    font-weight: 700;
    margin:  0 10px;
}
.DownloadApp2 .textSession h4 {
    color: var(--White);
    font-weight: 700;
    margin: 10px;
}

.DownloadApp2 .textSession .Apps {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 10px 0;
}
.DownloadApp2 .textSession .Apps .buttonsApps {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 250px;
    height: 60px;
    border-radius: 6px;
    border: 1px solid var(--White);
    background-color: rgba(255,255,255, 0.2);
    margin: 10px;
    cursor: pointer;
}
.DownloadApp2 .textSession .Apps .buttonsApps:hover {
    background-color: rgba(237,50,55, 0.5);
}
.DownloadApp2 .textSession .Apps .buttonsApps .iconApp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border: none;
    border-right: 1px solid var(--White);
    height: 60px;
    width: 60px;
}


.DownloadApp2 .textSession .Apps .buttonsApps .iconApp svg {
    font-size: 24px;
    color: var(--White);
}
.DownloadApp2 .textSession .Apps .buttonsApps .TextApp {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 10px;
}

.DownloadApp2 .textSession .Apps .buttonsApps .TextApp h4 {
    color: var(--White);
    font-weight: 700;
    margin: 0px;
}
.DownloadApp2 .textSession .Apps .buttonsApps .TextApp h6 {
    color: var(--White);
    margin: 0px;
}

.DownloadApp2 .imageApp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.DownloadApp2 .imageApp img {
    height: 280px;
    margin: 0 20px;
}

@media (max-width: 1280px) {
    /* .DownloadApp2 .imageApp img {
        width: 35%;
        margin: 0 20px;
    }

    @media (max-width: 1200px) {
        .DownloadApp2 .imageApp img {
            width: 40%;
            margin: 0 20px;
        }
    } */

    
    @media (max-width: 1120px) {
        /* .DownloadApp2 .imageApp img {
            width: 35%;
            margin: 0 20px;
        } */

        .DownloadApp2 .textSession {
            padding: 50px;
        }

        @media (max-width: 1035px) {
            /* .DownloadApp2 .imageApp img {
                width: 40%;
                margin: 0 20px;
            } */
            .DownloadApp2 {
                width: 95%;
            }

            .DownloadApp2 .textSession {
                padding: 30px;
            }

            
        }

        @media (max-width: 900px) {
            /* .DownloadApp2 .imageApp img {
                width: 40%;
                margin: 0 20px;
            }
     */
            .DownloadApp2 .textSession {
                padding: 10px;
            }

            
            
      
        }
            
        }
        
    }



