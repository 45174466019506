.ComingSoon {
    width: 100%;
    height: 100vh;
    /* background-color: #ab1b20; */
    background-color: var(--Primary);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh; 
    background-image: url("../../assets/images/backgroundCar2.jpg"); /* The image used */
    background-color: #cccccc; /* Used if the image is unavailable */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}
.ComingSoon .blockComing {
    width: 100%;
    height: 100vh;
    background-color: rgba(255,255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ComingSoon .blockComing  h1 {
    text-align: center;
    color: var(--Primary);
    font-weight: 900;
    margin: 10px;
}
.ComingSoon .blockComing  img {
    width: 700px;
    margin: 40px 10px;
}

.ComingSoon .blockComing  .coming {
    padding: 10px 60px;
    border: 2px solid var(--Primary);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 10px;
    border-radius: 50px;
}
.ComingSoon .blockComing  .coming h4 {
    text-align: center;
    color: var(--Primary);
    font-weight: 900;
}
.ComingSoon .blockComing  .logo {
    width: 180px;
    margin: 20px;
}
.ComingSoon .blockComing  .logo img {
    width: 100%;
    margin: 0px;
}


@media (max-width: 700px) {

    .ComingSoon .blockComing  h1 {
        font-size: 20px;
    }

    .ComingSoon .blockComing  img {
        width: 95%;
        margin: 40px 10px;
    }

    .ComingSoon .blockComing  .image {
        width: 261px;
        height: 164px;
        margin-top: 63px;
        margin-left: 1px;
    }


    .ComingSoon .blockComing  .coming {
        width: 200px;
    }


    
@media (max-width: 550px) {

    .ComingSoon .blockComing  .coming {
        width: 90%;
    }
}
}