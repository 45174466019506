.Home {
    width: 100%;
}

.Home .searchViewButton {
    display: none;
}

.Home .textHome {
    width: 100%;
    text-align: center;
}

.Home .textFeature {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 50px auto 0px auto;
    width: 1100px;
    text-align: left;
}

.Home .textFeature .buttonsAutos {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    width: 50%;
    margin: 0 20px;
}

.Home .textFeature .buttonsAutos button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 10px;
    background: var(--BorderInput);
    color: var(--Gray2);
    font-weight: 700;
    border-radius: 10px;
    margin: 3px 5px;
    font-size: 16px;
}
.Home .textFeature .buttonsAutos button:hover {
    background-color: var(--Primary);
    color: var(--White);
}

.Home .textFeature h3 {
    margin: 0 20px;
    font-weight: bold;
    color: var(--Gray);
}

.Home .textHome h3 {
    margin: 20px;
    font-weight: bold;
    color: var(--Gray);
}


.Home .textFeature2 {
    margin: 40px auto 0px;
    width: 1100px;
    text-align: left;
}

.Home .textFeature2 h3 {
    margin: 10px 20px;
    font-weight: bold;
    color: var(--Gray);
}
.Home .carroussel2 {
    width: 1100px;
    margin: 20px auto;
}
.Home .carroussel {
    width: 1100px;
    margin: 0px auto 20px auto;
}
.Home .carrousselBrabd {
    width: 1050px;
    margin: 100px auto 0px auto;
}
.Home .propaganda {
    width: 1200px;
    margin: 40px auto;
}

.Home .propaganda img {
    width: 100%;
    margin: 10px auto;
}


.content-modal-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    background-color: var(--Black);
}
.content-modal-home .itensModalHome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow:auto; 
    max-height: 500px;
    margin: 20px 10px;
}

.content-modal-home .itensModalHome img {
    width: 150px;
    margin: 20px;
}
.content-modal-home .itensModalHome h2 {
    font-weight: 700;
    color: var(--White);
    margin: 10px
}

.content-modal-home .itensModalHome form {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0;
}

.content-modal-home .itensModalHome  form select {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 10px 20px;
    background: var(--Background);
    border-radius: 6px;
}

.content-modal-home .itensModalHome form .dataButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
}
.content-modal-home .itensModalHome form .dataButtons button {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    background-color: var(--White);
    color: var(--Primary);
    margin: 5px;
    font-weight: 700;
}

.content-modal-home .itensModalHome form h3 {
    font-weight: 700;
    color: var(--White);
    margin-top: 10px;
}

.content-modal-home .itensModalHome form select {
    margin: 5px;
    font-weight: 700;
}

.content-modal-home .itensModalHome form button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    background-color: var(--White);
    color: var(--Primary);
    margin: 10px;
    font-weight: 700;
}
.content-modal-home .itensModalHome .loginAccount {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--BorderHover);
    margin: 20px 0;
    padding: 10px;
}

.content-modal-home .itensModalHome .loginAccount a {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    background-color: var(--White);
    color: var(--Primary);
    margin: 5px;
    font-weight: 700;
    text-decoration: none;
    font-size: 14px;
}

@media (max-width: 1280px) {

    .Home .carroussel2 {
        width: 1000px;
        margin: 20px auto;
    }

    .Home .textFeature2 {
        margin: 40px auto 0px;
        width: 1000px;
        text-align: left;
    }
    


@media (max-width: 1200px) {

    .Home .propaganda {
        width: 100%;
        padding: 0 40px;
    }

    

@media (max-width: 1030px) {

    .Home .carrousselBrabd {
        width: 95%;
        margin: 100px auto 0px auto;
    }
    
    .Home .carroussel {
        width: 95%;
        margin: 0px auto;
    }

    .Home .carroussel2 {
        width: 95%;
        margin: 20px auto;
    }

    .Home .textFeature2 {
        width: 95%;
    }

    @media (max-width: 1000px) {
        .Home .carroussel {
            width: 100%;
            margin: 0px auto;
        }

        .Home .carroussel2 {
            width: 100%;
            margin: 20px auto;
        }

        .Home .textFeature2 {
            width: 100%;
        }

        .Home .textFeature {
            width: 90%;
            text-align: left;
        }

        @media (max-width: 750px) {

            .Home .propaganda {
                width: 100%;
                padding: 0 20px;
            }

            .Home .searchView {
                display: none;
            }
            .Home .searchViewButton {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-top: -25px;
                z-index: 99;
                position: absolute;
            }

            .Home .searchViewButton button {
                border: 3px solid var(--White);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 15px 20px;
                Background-color: var(--Primary);
                color: var(--White);
                font-weight: 700;
                border-radius: 6px;
                width: 70%;
                box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
            }

            .Home .searchViewButton button svg {
                margin-right: 5px;
                font-size: 16px;
            }


            .Home .carrousselBrabd {
                width: 95%;
                margin: 100px auto 0px auto;
            }
            
            .Home .textFeature {
                width: 95%;
                text-align: left;
            }

            @media (max-width: 555px) {

                .Home .propaganda {
                    width: 100%;
                    padding: 0 10px;
                }
                .Home .textFeature {
                    width: 100%;
                    text-align: center;
                }

                .Home .textFeature {
                    width: 100%;
                    text-align: center;
                    flex-direction: column;
                }
                
                .Home .textFeature .buttonsAutos {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    width: 50%;
                    margin: 10px 20px;
                }

                .Home .textFeature .buttonsAutos button {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    padding: 10px;
                    background: var(--BorderInput);
                    color: var(--Gray2);
                    font-weight: 700;
                    border-radius: 10px;
                    margin: 3px 5px;
                    font-size: 20px;
                }

                @media (max-width: 400px) {

                    .content-modal-home .itensModalHome {
                        padding: 10px;
                    }
                    .content-modal-home .itensModalHome form {
                        width: 100%;
                    }

                    .content-modal-home .itensModalHome .loginAccount {
                        width: 100%;
                        margin: 20px 0 0 0;
                    }
                    
                }
         
            }
     
        }
 
    }
    
}
}
}