.block {
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: var(--White);
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    margin: 20px auto;
    padding-bottom: 10px;
}

.block .image {
    width: 100%; 
    height: 150px;
    border-radius: 6px 6px 0 0;
    object-fit: cover;
}
.block .image img {
    width: 100%; 
    height: 150px;
    border-radius: 6px 6px 0 0;
    object-fit: cover;
}


.block .featured {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    border-radius: 20px;
    position: absolute;
    margin-top: -15px;
    margin-left: 10px;
    padding: 10px 40px;
}

.block .featured p {
    color: var(--White);
    font-weight: 700;
}

.block .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Secondary);
    border-radius: 6px;
    position: absolute;
    margin-top: 110px;
    margin-left: 10px;
    padding: 10px 20px;
}

.block .status p {
    color: var(--White);
    font-weight: 700;
}

.block .heart {
    position: absolute;
    margin-top: 115px;
    margin-left: 265px;
}

.block .heart svg {
    color: var(--White);
    font-size: 24px;
    cursor: pointer;
}
.block .heart svg:hover {
    color: var(--Primary);
}
.block .heart2 {
    position: absolute;
    margin-top: 115px;
    margin-left: 200px;
}

.block .heart2 svg {
    color: var(--Primary);
    font-size: 24px;
    cursor: pointer;
}
.block .heart svg:hover {
    color: var(--Primary);
}




.block .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}
.block .text h2 {
    color: var(--Primary);
    font-weight: 700;
    margin: 20px 10px 5px 10px;
}
.block .text h3 {
    color: var(--Paragraph);
    font-weight: 700;
    margin: 10px 10px 0px 10px;
}
.block .text h3 span {
    color: var(--Primary);
}

.block .text a {
    text-decoration: none;
}
.block .text h4 {
    color: var(--Gray);
    margin: 0 10px;
}
.block .text h5 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--Gray);
    margin: 0 10px 5px 10px;
    font-weight: 700;
}
.block .text h6 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--Gray);
    margin: 0 10px;
}
.block .text .icons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.block .text .icons .iconUnic {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    padding: 5px;
    margin: 5px;
    color: var(--Gray);
}

.block .text .icons .iconUnic p {
    color: var(--Paragraph);
    font-weight: 800;
    font-size: 10px;
}
.block .text .icons .iconUnic .simbol {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    margin-top: 2px;
}

.block .text .icons .iconUnic .simbol svg {
    font-size: 14px;
    margin-right: 5px;
    color: var(--Gray);
}
.block .text .icons .iconUnic .simbol img {
    width: 14px;
    margin-right: 3px;
}
.block .text .pricing {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: flex-start;
}

.block .text .pricing h5 {
    color: var(--Paragraph);
    font-weight: 700;
    margin: 5px 10px;
}
.block .text .pricing h2 {
    color: var(--Primary);
    font-weight: 700;
    margin: 0 10px;
    font-size: 22px;
}


@media (max-width: 750px) {
    .Featured {
        margin: 350px 0 50px 0;
    }

    @media (max-width: 550px) {
        .Featured {
            margin: 400px 0 50px 0;
        }
    }
}