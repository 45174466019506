.logoModel {
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: var(--White);
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin: 5px auto;
    padding: 10px 15px;
    text-decoration: none;
}
a {
    text-decoration: none;
}
.logoModel .Model {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}
.logoModel .Model h3 {
    font-weight: 700;
    color: var(--Paragraph);
    font-size:16px;
}
.logoModel .Model h2 {
    font-weight: 900;
    color: var(--Primary);
    font-size:20px;
}

.logoModel img {
    width: 90%; 
}

.logoModel2 {
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: var(--White);
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin: 5px auto;
    padding: 10px 15px;
    text-decoration: none;
}
a {
    text-decoration: none;
}
.logoModel2 .Model2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}
.logoModel2 .Model2 h3 {
    font-weight: 700;
    color: var(--Paragraph);
    font-size:16px;
}
.logoModel2 .Model2 h2 {
    font-weight: 900;
    color: var(--Primary);
    font-size:20px;
}

.logoModel2 img {
    height: 85px; 
}

@media (max-width: 500px) {
    .logoModel {
        width: 90%;
    }
    .logoModel2 {
        width: 90%;
    }
}

